import { persistStore, persistReducer, Persistor } from 'redux-persist';
import { createWrapper } from 'next-redux-wrapper';
import { AppConfig, ReduxPersistConfig } from '@/config';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { ReduxReducer } from './ReduxReducer';

type StoreGeneric = ReturnType<typeof configureStore>;

export const makeStore = (): StoreGeneric & {
  __persistor?: Persistor;
} => {
  if (AppConfig.ENV.IS_SERVER) {
    return configureStore({
      reducer: ReduxReducer,
      devTools: !AppConfig.ENV.PRODUCTION,
      middleware: [thunk],
    });
  }
  const persistedReducer = persistReducer(ReduxPersistConfig, ReduxReducer);
  const store: any = configureStore({
    reducer: persistedReducer,
    devTools: !AppConfig.ENV.PRODUCTION,
    middleware: [thunk],
  });
  store.__persistor = persistStore(store); // Nasty hack
  return store;
};

export const ReduxStore = makeStore();

export const ReduxPersistor = ReduxStore.__persistor!;

// MARK: Will be used after it supports Next.js 13 App Router
export const ReduxWrapper = createWrapper(() => ReduxStore);
