import { ReduxStoreState } from './ReduxState';
import { ReduxStore } from './ReduxStore';

export function reduxDispatcher(thunkAction: { type: string; payload?: any }) {
  return ReduxStore.dispatch(thunkAction);
}

export function reduxStateSelector() {
  return ReduxStore.getState() as ReduxStoreState;
}

// Returns unsubscribe function
export function reduxSubscriber(callback: (state: ReduxStoreState) => void) {
  return ReduxStore.subscribe(() => {
    callback(ReduxStore.getState() as ReduxStoreState);
  });
}
