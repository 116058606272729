import React, { ReactNode, useState } from 'react';
import { ConfigProvider, theme } from 'antd';
import { useSelector } from '@/core/lib/redux/hooks';
// import { StyleProvider } from '@ant-design/cssinjs';

const { defaultAlgorithm, darkAlgorithm } = theme;

export const withTheme = (node: ReactNode) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useSelector((state) => state.appSettings?.theme || 'light');

  return (
    <ConfigProvider
      theme={{
        algorithm: theme === 'light' ? defaultAlgorithm : darkAlgorithm,
      }}
    >
      {node}
    </ConfigProvider>
  );
};
