import { combineReducers } from 'redux';
import { ReduxStateKey } from '@/types';
import { authSlice, appSettingsSlice } from '@/core/app/states';

const mapReducer = {
  [ReduxStateKey.Auth]: authSlice.reducer,
  [ReduxStateKey.AppSettings]: appSettingsSlice.reducer,
};

export const ReduxReducer = combineReducers(mapReducer);
