'use client';

import { initGoogleAnalytics } from '@/core/lib/analytics/google-analytics';
import { AppConfig } from '@/config';
import { withTheme } from '@/shared/theme';
import { PersistGate } from 'redux-persist/integration/react';
import { ReduxPersistor, ReduxStore } from '@/core/lib/redux';
import { ReactNode } from 'react';
import { Provider } from 'react-redux';

if (AppConfig.ENV.PRODUCTION) {
  initGoogleAnalytics();
}

const AppContent = ({ children }: { children: ReactNode }) =>
  withTheme(children);

export default function ClientRootLayout({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Provider store={ReduxStore}>
      <PersistGate persistor={ReduxPersistor}>
        <AppContent>{children}</AppContent>
      </PersistGate>
    </Provider>
  );
}
